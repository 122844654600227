import {ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {Observable, of} from 'rxjs';
import {IPredictionResponse, IPropertiesFeature} from '../../models/interfaces/IPredictionResponse';
import {cloneDeep, last, uniqBy} from 'lodash';
import {PagesFacade} from '../../../pages/pages.facade';
import {Address} from '../../models/address.model';
import {FormatTypes, getFieldsToSearchWithoutFilters, getFormatWithCountryFormat} from '../../enums/format-types';
import {IPhotonAdditionalFilter} from '../../models/interfaces/IPhotonAdditionalFilter';

@Component({
    selector: 'app-address-autocomplete',
    templateUrl: './address-autocomplete.component.html',
    styleUrls: ['./address-autocomplete.component.scss']
})
export class AddressAutocompleteComponent implements OnInit, OnChanges {

    @Input() public placeholder: string;
    @Input() public formatType: string;
    @Input() public valueFromInput: Address | undefined | string = undefined;
    @Input() public otherFilters: IPhotonAdditionalFilter = {};
    @Output() changeSelectedItemEvent = new EventEmitter<IPropertiesFeature>();

    private readonly additionalQueryParams: string = '&limit=10000&lang=default';

    public predictions: Array<IPropertiesFeature> = [];
    public isLoadingSelect = false;
    public selectedItem: IPropertiesFeature | undefined = undefined;
    public searchParams: string | undefined = undefined;

    public refInitLoadItems: () => Observable<IPredictionResponse>;
    public refSearchingCallback: () => Observable<IPredictionResponse>;

    constructor(private pagesFacade: PagesFacade,
                private cdr: ChangeDetectorRef) {
    }

    ngOnInit() {
        this.refInitLoadItems = this.init.bind(this);
        this.refSearchingCallback = this.search.bind(this);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes?.valueFromInput) {
            this.selectedItem = changes?.valueFromInput?.currentValue ? {label: changes?.valueFromInput?.currentValue, name: changes?.valueFromInput?.currentValue} : undefined;
        }
        this.searchParams = changes?.valueFromInput?.currentValue ?? this.searchParams;
        this.otherFilters = changes?.otherFilters?.currentValue ?? this.otherFilters;
    }


    public search(): Observable<IPredictionResponse> {
        if (this.formatType === FormatTypes.CITY || this.formatType === FormatTypes.COUNTY) {
            return this.pagesFacade.getPredictionByFilters(this.searchParams, this.additionalQueryParams, this.otherFilters);
        }
        if (getFieldsToSearchWithoutFilters().includes(this.formatType)) {
            return this.pagesFacade.getPredictionByFilters(this.searchParams, this.additionalQueryParams);
        }
        return of({} as IPredictionResponse);
    }

    public changeVal(event: string) {
        this.searchParams = event;
    }

    public init(): Observable<IPredictionResponse> {
        return this.pagesFacade.getInitialPhotonValues();
    }

    public changeSelectedItem(val: IPropertiesFeature) {
        if (!val) {
            this.resetSelectedItem();
            return;
        }
        this.selectedItem = cloneDeep(val);
        this.selectedItem.label = this.selectedItem?.name;
        this.changeSelectedItemEvent.emit(this.selectedItem);
        this.predictions = [];
    }

    public setLastValue(isClose: boolean) {
        if (isClose && last(this.predictions) && !this.selectedItem?.osm_id) {
            this.changeSelectedItem(last(this.predictions) as IPropertiesFeature);
        }
    }

    public resetSelectedItem() {
        this.selectedItem = undefined;
        this.searchParams = undefined;
        this.predictions = [];
        this.changeSelectedItemEvent.emit({});
    }

    private assignLabelToProperties(p: IPropertiesFeature) {
        if (this.formatType === FormatTypes.ADDRESS) {
            p.label = `${p?.name ? (p.name + ', ') : ''}${p?.city ? (p.city + ', ') : ''}${p?.postcode ? (p.postcode + ', ') : ''}${p?.state ? (p.state + ', ') : ''}${p?.country ?? ''}`;
        }
        if (this.formatType === FormatTypes.CITY) {
            p.label = `${p?.name ? (p.name + ', ') : ''}${p?.state ? (p.state + ', ') : ''}${p?.country ?? ''}`;
        }
        if (this.formatType === FormatTypes.COUNTY) {
            p.label = `${p?.name ? (p.name + ', ') : ''}${p?.country ?? ''}`;
        }
        if (getFormatWithCountryFormat().includes(this.formatType)) {
            p.label = p?.name ?? '';
        }
    }

    setPredictions(event: IPredictionResponse) {
        if (!this.searchParams) {
            this.predictions = [];
            return;
        }
        let predictions: Array<IPropertiesFeature> = (event as IPredictionResponse)?.features
            .map(el => {
                this.assignLabelToProperties(el.properties);
                return el.properties;
            });

        if (this.formatType === FormatTypes.ADDRESS) {
            predictions = predictions.filter(el => el.type === 'street' && el.countrycode === 'IT');
        }

        if (this.formatType === FormatTypes.CITY) {
            predictions = predictions.filter(el => el.type === 'city')
                .filter(el => this.otherFilters?.country ? el.country === this.otherFilters?.country : el === el)
                .filter(el => this.otherFilters?.county ? el.county === this.otherFilters?.county : el === el);
        }

        if (this.formatType === FormatTypes.COUNTY) {
            predictions = predictions.filter(el => el.type === 'county')
                .filter(el => this.otherFilters?.country ? el.country === this.otherFilters?.country : el === el);
        }

        if (getFormatWithCountryFormat().includes(this.formatType)) {
            predictions = predictions.filter(el => el.type === 'country');
        }

        predictions.push({label: this.searchParams, name: this.searchParams});
        this.predictions = uniqBy(predictions, 'label');
        this.cdr.detectChanges();
    }
}
