import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {ILabelValueRoute} from '../../modules/profile/interfaces/TabSelectedCode';
import {IPredictionResponse} from '../models/interfaces/IPredictionResponse';
import {environment} from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import {getPhotonQuerySearch, IPhotonAdditionalFilter} from '../models/interfaces/IPhotonAdditionalFilter';
import {UploadFiles} from '../models/upload-files.model';
import {FileUploaded} from '../models/file-uploaded.model';
import {ContactUsModel} from '../models/contact-us.model';

@Injectable({
    providedIn: 'root'
})
export class SharedService {

    private readonly ENDPOINT_V1: string = `${environment.addressPressupBackofficeAPI}${environment.api_v1}`;
    // private readonly ENDPOINT_V2: string = `${environment.addressPressupBackofficeAPI}${environment.api_v2}`;
    private _tabProfileSelected$ = new BehaviorSubject<ILabelValueRoute>(undefined);
    private _searchTerms$ = new BehaviorSubject<string>(undefined);

    constructor(private http: HttpClient) {
    }

    public getTabProfileSelected$(): Observable<ILabelValueRoute> {
        return this._tabProfileSelected$.asObservable();
    }

    public setTabProfileSelected$(value: ILabelValueRoute): void {
        return this._tabProfileSelected$.next(value);
    }

    public getSearchTerms$(): Observable<string> {
        return this._searchTerms$.asObservable();
    }

    public setSearchTerms$(value: string): void {
        return this._searchTerms$.next(value);
    }

    //  photon's api
    public getInitialPhotonValues(): Observable<IPredictionResponse> {
        return this.http.get<IPredictionResponse>(`${environment.photonApi}?q=`);
    }

    public getPredictionByFilters(searchParams: string | undefined, additionalQueryParams: string, otherFilters?: IPhotonAdditionalFilter): Observable<IPredictionResponse> {
        return this.http.get<IPredictionResponse>(`${environment.photonApi}?q=${searchParams}${getPhotonQuerySearch(otherFilters) ?? ''}${additionalQueryParams}`);
    }

    public getByDynamicUrl<T>(url: string): Observable<T> {
        return this.http.get<T>(url);
    }

    public uploadFile(files: UploadFiles): Observable<FileUploaded> {
        const formData: FormData = new FormData();
        formData.append('files', files?.files[0]);
        return this.http.post<FileUploaded>(`${environment.addressPressupBackoffice}/document`, formData);
    }

    public sendMailDesignYourIdea(contact: ContactUsModel): Observable<any> {
        return this.http.post<any>(`${this.ENDPOINT_V1}/utility/email`, contact);
    }

    public getCaptcha(): Observable<any> {
        return this.http.get<any>(`${this.ENDPOINT_V1}/utility/captcha`, {observe: 'response'});
    }
}
